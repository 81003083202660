import Layout from '@components/layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { ContentContainer, Icon, MarkdownHandler, PageContainer } from '@system'
import ACC_THEME from '@themes'
import { Link } from 'gatsby'
import React from 'react'
import Slideshow from 'react-slick'
import SbEditable from 'storyblok-react'
import '../../styles/slick-theme.css'
import '../../styles/slick.css'

const useStyles = makeStyles(() => ({
  championsDetailRoot: {
    display: 'grid',
    gap: '32px',
    padding: '32px 0',
    [ACC_THEME.breakpoints.up('md')]: {
      gap: '56px',
      gridTemplateColumns: '1fr 300px',
      padding: '56px 0',
    },
  },
  championsDetailSlideshowColumn: {
    // Allows the slideshow to fill the width of this column
    overflow: 'hidden',
    position: 'relative',
  },
  championsDetailBackLink: {
    alignItems: 'center',
    color: ACC_THEME.palette.common.black,
    display: 'flex',
    fontWeight: ACC_THEME.typography.fontWeightExtraBold,
    gap: '10px',
    marginBottom: '32px',
    textDecoration: 'none',
  },
  championsDetailBackLinkIcon: {
    alignItems: 'center',
    backgroundColor: ACC_THEME.palette.common.black,
    borderRadius: '50%',
    display: 'flex',
    height: '38px',
    justifyContent: 'center',
    width: '38px',
  },
  championsDetailBio: {
    alignItems: 'flex-end',
    display: 'flex',
    gap: '20px',
    marginBottom: '28px',
  },
  championsDetailBioImage: {
    borderRadius: '6px',
    height: '136px',
    overflow: 'hidden',
    position: 'relative',
    width: '136px',
    [ACC_THEME.breakpoints.up('md')]: {
      height: '198px',
      width: '198px',
    },
  },
  championsDetailBioInfo: {
    color: ACC_THEME.palette.common.black,
  },
  championsDetailBioInfoName: {
    fontSize: '18px',
    fontWeight: ACC_THEME.typography.fontWeightExtraBold,
    margin: '0',
  },
  championsDetailBioInfoList: {
    listStyleType: 'none',
    margin: '0',
    padding: '0',
  },
  championsDetailSocialLinks: {
    alignItems: 'center',
    display: 'flex',
    gap: '20px',
    marginTop: '10px',
  },
  championsDetailMarkdown: {
    fontFamily: 'inherit',
    '& p': {
      display: 'block',
      margin: '16px 0',
    },
    '& strong': {
      display: 'block',
      fontWeight: ACC_THEME.typography.fontWeightExtraBold,
      marginBottom: '2px',
    },
  },
  championsDetailMarkdownHeading: {
    fontSize: '24px',
    fontWeight: ACC_THEME.typography.fontWeightExtraBold,
    margin: '0 0 20px',
  },
  championsDetailMeetBio: {
    color: ACC_THEME.palette.common.black,
    marginBottom: '36px',
  },
  championDetailsSlideshow: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    '& .slick-dots': {
      bottom: '24px',
      display: 'flex !important',
      justifyContent: 'center',
      '& ul': {
        display: 'flex !important',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '32px',
        gap: '6px',
        listStyleType: 'none',
        margin: '0',
        padding: '6px 8px',
      },
      '& li': {
        backgroundColor: 'rgba(255,255,255,0.7)',
        borderRadius: '50%',
        height: '6px',
        margin: '0',
        width: '6px',
        '&.slick-active': {
          backgroundColor: ACC_THEME.palette.common.white,
        },
      },
    },
  },
  championDetailsSlideshowArrow: {
    alignItems: 'center',
    borderRadius: '50%',
    color: ACC_THEME.palette.common.white,
    cursor: 'pointer',
    display: 'flex !important',
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '36px',
    justifyContent: 'center',
    position: 'absolute',
    top: 'calc(50% - 16px)',
    width: '36px',
    zIndex: '1',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  },
  championDetailsSlideshowImage: {
    aspectRatio: '16/9',
    display: 'block',
    objectFit: 'cover',
    width: '100%',
  },
  championsDetailAboutBio: {
    backgroundColor: ACC_THEME.palette.common.black,
    color: ACC_THEME.palette.common.white,
    padding: '16px',
  },
}))

const ChampionsDetail = ({ blok, championsDetailModulesStory }) => {
  const {
    aboutBio,
    body,
    company,
    firstName,
    headshot,
    images,
    jobTitle,
    lastName,
    location,
    meetBio,
    moreResources,
    socialLinks,
    metaData,
  } = blok
  const classes = useStyles()

  const SlideshowArrow = ({ chevron, onClick }) => {
    return (
      <div
        className={classes.championDetailsSlideshowArrow}
        onClick={onClick}
        style={{
          left: chevron === 'Left' ? '16px' : null,
          right: chevron === 'Right' ? '16px' : null,
        }}
      >
        <Icon>{`Chevron${chevron}`}</Icon>
      </div>
    )
  }

  const slideshowSettings = {
    autoplay: false,
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    nextArrow: <SlideshowArrow chevron="Right" />,
    prevArrow: <SlideshowArrow chevron="Left" />,
  }

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={blok}>
          <PageContainer>
            <ContentContainer>
              <div className={classes.championsDetailRoot}>
                <div className={classes.championsDetailSlideshowColumn}>
                  <Link
                    className={classes.championsDetailBackLink}
                    to="/champion/"
                  >
                    <span className={classes.championsDetailBackLinkIcon}>
                      <Icon>ArrowLeft</Icon>
                    </span>
                    Champions
                  </Link>
                  <div className={classes.championsDetailBio}>
                    {headshot?.length > 0 && (
                      <div className={classes.championsDetailBioImage}>
                        {renderBloks(headshot)}
                      </div>
                    )}
                    <div className={classes.championsDetailBioInfo}>
                      {firstName && lastName && (
                        <h1 className={classes.championsDetailBioInfoName}>
                          {firstName} {lastName}
                        </h1>
                      )}
                      <ul className={classes.championsDetailBioInfoList}>
                        {jobTitle && <li>{jobTitle}</li>}
                        {company && <li>{company}</li>}
                        {location && <li>{location}</li>}
                      </ul>
                      {socialLinks?.length > 0 && (
                        <div className={classes.championsDetailSocialLinks}>
                          {renderBloks(socialLinks)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.championsDetailMeetBio}>
                    {firstName && meetBio && (
                      <>
                        <h2 className={classes.championsDetailMarkdownHeading}>
                          Meet {firstName}
                        </h2>
                        <div className={classes.championsDetailMarkdown}>
                          <MarkdownHandler>{meetBio}</MarkdownHandler>
                        </div>
                      </>
                    )}
                  </div>
                  {images?.length === 1 && renderBloks(images)}
                  {images?.length > 1 && (
                    <Slideshow
                      className={classes.championDetailsSlideshow}
                      {...slideshowSettings}
                    >
                      {images.map((image) => (
                        <img
                          className={classes.championDetailsSlideshowImage}
                          lazy={true}
                          src={`${image.image}/m/800x0/smart/filters:quality(90)`}
                        />
                      ))}
                    </Slideshow>
                  )}
                </div>
                <div>
                  <div className={classes.championsDetailAboutBio}>
                    {firstName && aboutBio && (
                      <>
                        <h2 className={classes.championsDetailMarkdownHeading}>
                          About {firstName}
                        </h2>
                        <div className={classes.championsDetailMarkdown}>
                          <MarkdownHandler>{aboutBio}</MarkdownHandler>
                        </div>
                      </>
                    )}
                    {firstName && moreResources?.length > 0 && (
                      <>
                        <h2 className={classes.championsDetailMarkdownHeading}>
                          More from {firstName}
                        </h2>
                        {renderBloks(moreResources)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </ContentContainer>
          </PageContainer>
        </SbEditable>
        {body?.length > 0 && renderBloks(body)}
        {championsDetailModulesStory && renderBlok(championsDetailModulesStory)}
      </ThemeProvider>
    </Layout>
  )
}

export default ChampionsDetail
